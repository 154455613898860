import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import projects from "./slices/projectSlice";
import articles from "./slices/articlesSlice";
import preview from "./slices/articlePreviewSlice";

const rootReducer = asyncReducers => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    projects,
    articles,
    preview,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
