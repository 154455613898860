import { toast } from "sonner";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN, AUTH_USER_DATA } from "constants/AuthConstant";
import FirebaseAuthService from "services/FirebaseAuthService";
import { formatErrorMessage } from "services/utils";
// import AuthService from "services/AuthService";

const storedUser = localStorage.getItem(AUTH_USER_DATA);
const storeToken = localStorage.getItem(AUTH_TOKEN);

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: storeToken || null,
  notificationToken: null,
  user: storedUser ? JSON.parse(storedUser) : {},
};

export const signIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const { user, token } = await FirebaseAuthService.signInEmailRequest(
        data
      );
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_USER_DATA, JSON.stringify(user));
      return { user, token };
    } catch (err) {
      switch (true) {
        case err.message ===
          "FirebaseError: Firebase: Error (auth/invalid-login-credentials).":
          return rejectWithValue("invalid login or password");

        default:
          return rejectWithValue(
            err?.message ? formatErrorMessage(err?.message) : "Error!!"
          );
      }
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    try {
      return await FirebaseAuthService.signUpEmailRequest(data);
    } catch (err) {
      switch (true) {
        case err.message ===
          "FirebaseError: Firebase: Error (auth/email-already-in-use).":
          return rejectWithValue("email already in use");

        default:
          return rejectWithValue(
            err?.message ? formatErrorMessage(err?.message) : "Error!!"
          );
      }
    }
  }
);

export const passwordRestore = createAsyncThunk(
  "auth/passwordRestore",
  async (email, { rejectWithValue }) => {
    try {
      await FirebaseAuthService.passwordRestore(email);
      toast.success(
        "An email with a password reset link has been sent to your inbox."
      );
    } catch (err) {
      switch (true) {
        case err.message ===
          "FirebaseError: Firebase: Error (auth/too-many-requests).":
          toast.error("Too many requests");
          return rejectWithValue("too many requests");

        default:
          toast.error(
            err?.message ? formatErrorMessage(err?.message) : "Error!!"
          );
          return rejectWithValue(
            err?.message ? formatErrorMessage(err?.message) : "Error!!"
          );
      }
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  const response = await FirebaseAuthService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_USER_DATA);
  return response.data;
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    try {
      const { user, token } = await FirebaseAuthService.signInGoogleRequest();
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_USER_DATA, JSON.stringify(user));
      return { user, token };
    } catch (err) {
      console.log(err);
      return rejectWithValue(
        err?.message ? formatErrorMessage(err?.message) : "Error!!"
      );
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    try {
      const { user, token } = await FirebaseAuthService.signInFacebookRequest();
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_USER_DATA, JSON.stringify(user));
      return { user, token };
    } catch (err) {
      return rejectWithValue(
        err?.message ? formatErrorMessage(err?.message) : "Error!!"
      );
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    setNotificationToken: (state, action) => {
      state.notificationToken = action.payload;
    },
    hideAuthMessage: state => {
      state.showMessage = false;
      state.message = "";
    },
    signOutSuccess: state => {
      state.loading = false;
      state.token = null;
      state.notificationToken = null;
      state.redirect = "/";
    },
    showLoading: state => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
    updateUserData: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      localStorage.setItem(
        AUTH_USER_DATA,
        JSON.stringify({ ...state.user, ...action.payload })
      );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signIn.pending, state => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.pending, state => {
        state.loading = true;
      })
      .addCase(signOut.fulfilled, state => {
        state.loading = false;
        state.token = null;
        state.notificationToken = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, state => {
        state.loading = false;
        state.token = null;
        state.notificationToken = null;
        state.user = {};
        state.redirect = "/";
      })
      .addCase(signUp.pending, state => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.message =
          "An email verification message has been sent to your email address.";
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(passwordRestore.pending, state => {
        state.loading = true;
      })
      .addCase(passwordRestore.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(passwordRestore.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, state => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, state => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  setNotificationToken,
  signOutSuccess,
  showLoading,
  signInSuccess,
  updateUserData,
} = authSlice.actions;

export default authSlice.reducer;
