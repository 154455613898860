import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UNAUTHENTICATED_ENTRY } from "configs/AppConfig";
import FirebaseUserService from "services/FirebaseUserService";
import { isEqual } from "lodash";
import { updateUserData } from "store/slices/authSlice";

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const reduxUser = useSelector(state => state.auth.user);
  const location = useLocation();

  useEffect(() => {
    const reduxUserDataCheck = async () => {
      const fireBaseUser = await FirebaseUserService.getUserData();
      if (reduxUser && fireBaseUser && !isEqual(reduxUser, fireBaseUser)) {
        dispatch(updateUserData(fireBaseUser));
      }
    };
    reduxUserDataCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!token) {
    return <Navigate to={UNAUTHENTICATED_ENTRY} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
