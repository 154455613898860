const { REACT_APP_PUBLIC_URL, REACT_APP_PUBLIC_URL_DEV } = process.env;

const environment = process.env.NODE_ENV || "production";

export const PUBLIC_BASE_URL =
  environment === "production"
    ? REACT_APP_PUBLIC_URL
    : REACT_APP_PUBLIC_URL_DEV;

// PUBLIC_URL => PUBLIC_BASE_URL (index.html in public)
// process.env.PUBLIC_URL => PUBLIC_BASE_URL (App.js)
// process.env.PUBLIC_URL => PUBLIC_BASE_URL (serviceWorker.js)
