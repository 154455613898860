import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  projectId: null,
  data: [],
};

export const articlePreviewSlice = createSlice({
  name: "articlePreview",
  initialState,
  reducers: {
    setPreview: (state, action) => {
      state.projectId = action.payload?.subjectId;
      state.data = action.payload;
      state.loading = false;
    },
    clearPreview: (state, _) => {
      state.projectId = null;
      state.data = [];
      state.loading = false;
    },
    startPreviewGeneration: state => {
      state.projectId = null;
      state.data = [];
      state.loading = true;
    },
    turnOnLoading: state => {
      state.loading = true;
    },
    turnOffLoading: state => {
      state.loading = false;
    },
  },
});

export const {
  setPreview,
  clearPreview,
  startPreviewGeneration,
  turnOnLoading,
  turnOffLoading,
} = articlePreviewSlice.actions;

export default articlePreviewSlice.reducer;
