const fireBaseApiKey = process.env.REACT_APP_API_KEY;

const FirebaseConfig = {
  apiKey: fireBaseApiKey,
  authDomain: "news-ai-285f0.firebaseapp.com",
  projectId: "news-ai-285f0",
  storageBucket: "news-ai-285f0.appspot.com",
  messagingSenderId: "414135189281",
  appId: "1:414135189281:web:dd73e0a3bcd7f068b64860",
};

export default FirebaseConfig;
