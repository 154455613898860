import {
  db,
  auth,
  signInWithEmailAndPassword,
  signOut,
  googleAuthProvider,
  facebookAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "auth/FirebaseAuth";
import uniqid from "uniqid";

import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
} from "firebase/firestore";

import { BASE_FRONT_URL } from "constants/AuthConstant";

const FREE_SUBSCRIPTION_ID = "YZEDxThrbbT0VJKd6dHn";

const FirebaseAuthService = {};

FirebaseAuthService.signInEmailRequest = async data => {
  const { email, password } = data;
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    if (user.emailVerified === false) {
      throw Error(`Email not verified`);
    }

    const savedToken = user.accessToken;

    const userDoc = await FirebaseAuthService.findUserByEmail(email);
    const userData = userDoc.data();
    return { user: userData, token: savedToken };
  } catch (error) {
    throw Error(error);
  }
};

FirebaseAuthService.signOutRequest = async () =>
  await signOut(auth)
    .then(user => user)
    .catch(err => err);

FirebaseAuthService.signInGoogleRequest = async () => {
  try {
    const { user } = await signInWithPopup(auth, googleAuthProvider);
    const { email, displayName, photoURL, uid, accessToken } = user;
    const savedToken = accessToken;

    const userDoc = await FirebaseAuthService.findUserByEmail(email);
    if (userDoc) {
      let userData = userDoc.data();
      return { user: userData, token: savedToken };
    } else {
      const userData = await FirebaseAuthService.handleNewUser(
        email,
        uniqid(),
        photoURL,
        displayName,
        uid
      );
      return { user: userData, token: savedToken };
    }
  } catch (error) {
    throw new Error(error);
  }
};

FirebaseAuthService.signInFacebookRequest = async () => {
  try {
    const { user, _tokenResponse } = await signInWithPopup(
      auth,
      facebookAuthProvider
    );

    const { uid, accessToken } = user;
    const { photoUrl, email, displayName } = _tokenResponse;

    const savedToken = accessToken;

    const userDoc = await FirebaseAuthService.findUserByEmail(email);
    if (userDoc) {
      let userData = userDoc.data();
      return { user: userData, token: savedToken };
    } else {
      const userData = await FirebaseAuthService.handleNewUser(
        email,
        uniqid(),
        photoUrl,
        displayName,
        uid
      );
      return { user: userData, token: savedToken };
    }
  } catch (error) {
    throw new Error(error);
  }
};

FirebaseAuthService.passwordRestore = async email => {
  try {
    return await sendPasswordResetEmail(auth, email, {
      url: `${BASE_FRONT_URL}/auth/login`,
      // show btn with redirect in firebase ui
    });
  } catch (error) {
    throw new Error(error);
  }
};

FirebaseAuthService.signUpEmailRequest = async data => {
  const { email, password } = data;

  try {
    const newUser = await FirebaseAuthService.handleNewUser(email, password);
    return newUser;
  } catch (error) {
    throw new Error(error);
  }
};

FirebaseAuthService.findUserByEmail = async email => {
  try {
    const usersCollection = collection(db, "users");
    const q = query(usersCollection, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0];
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

FirebaseAuthService.handleNewUser = async (
  email,
  password,
  userPhotoURL,
  name,
  uid
) => {
  try {
    if (uid) {
      const q = query(
        collection(db, "subscriptions"),
        where("name", "==", "Free")
      );
      const subscriptionSnapshot = await getDocs(q);

      const newUser = {
        owner_uid: uid,
        email: email,
        displayName: name ? name : "",
        subscription: { id: subscriptionSnapshot.docs[0].id },
        FCMTokens: [],
        photoURL: userPhotoURL ? userPhotoURL : null,
        autoApproveSocialMedia: false,
      };

      await setDoc(doc(db, "users", uid), newUser);
      return newUser;
    } else {
      console.log(`user`);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const { user } = userCredential;

      await sendEmailVerification(user);

      await updateProfile(user, {
        photoURL: userPhotoURL ? userPhotoURL : null,
        displayName: name ? name : "",
      });
      const { uid, displayName, photoURL, accessToken } = user;

      const newUser = {
        owner_uid: uid,
        email: email,
        displayName: displayName ? displayName : "",
        subscription: { id: FREE_SUBSCRIPTION_ID },
        FCMTokens: [],
        photoURL,
        autoApproveSocialMedia: false,
      };

      await setDoc(doc(db, "users", uid), newUser);
      return { user: newUser, token: accessToken };
    }
  } catch (error) {
    throw error;
  }
};

export default FirebaseAuthService;
