import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AUTHENTICATED_ENTRY } from "configs/AppConfig";

const publicUrls = ["/main/terms-of-service", "/main/privacy-policy"];

const PublicRoute = () => {
  const { token } = useSelector(state => state.auth);
  const location = useLocation();
  const noNeedToRedirect = publicUrls.includes(location.pathname);

  if (noNeedToRedirect) return <Outlet />;
  // console.log(`location`, location.pathname);
  return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet />;
};

export default PublicRoute;
