const {
  REACT_APP_NEWS_AI_SERVICE_API_URL,
  REACT_APP_NEWS_AI_SERVICE_API_URL_DEV,
} = process.env;

const environment = process.env.NODE_ENV || "production";

export const API_BASE_URL =
  environment === "production"
    ? REACT_APP_NEWS_AI_SERVICE_API_URL
    : REACT_APP_NEWS_AI_SERVICE_API_URL_DEV;
