import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FirebaseArticlesService from "services/FirebaseArticlesService";
import { formatErrorMessage } from "services/utils";

export const initialState = {
  loading: false,
  articlesMessage: "",
  messageType: "",
  showMessage: false,
  redirect: "",
  data: [],
};

export const fetchArticles = createAsyncThunk(
  "articles/",
  async (projectId, { rejectWithValue }) => {
    try {
      const articlesData = await FirebaseArticlesService.fetchArticles(
        projectId
      );

      return articlesData;
    } catch (err) {
      switch (true) {
        default:
          return rejectWithValue(
            err?.message ? formatErrorMessage(err?.message) : "Error!!"
          );
      }
    }
  }
);

export const postArticle = createAsyncThunk(
  "articles/post",
  async (data, { rejectWithValue }) => {
    try {
      // const articlesData = await FirebaseArticlesService.postArticle(data);
      FirebaseArticlesService.postArticle(data);
      // return articlesData;
    } catch (err) {
      console.log(err);
    }
  }
);

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    showArticlesMessage: (state, action) => {
      state.articlesMessage = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideArticlesMessage: state => {
      state.articlesMessage = "";
      state.messageType = "";
      state.showMessage = false;
    },
    showLoading: state => {
      state.loading = true;
    },
    clearRedirect: state => {
      state.redirect = "";
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchArticles.pending, state => {
        state.loading = true;
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchArticles.rejected, (state, action) => {
        state.articlesMessage = action.payload;
        state.messageType = "error";
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(postArticle.fulfilled, (state, action) => {
        // state.articlesMessage = action.payload.data.message;
        // state.messageType = "generated on demand success";
        // state.showMessage = true;
      })
      .addCase(postArticle.rejected, (state, action) => {
        // state.articlesMessage = action.payload;
        // state.messageType = "generated on demand error";
        // state.showMessage = true;
      });
  },
});
export const {
  showArticlesMessage,
  hideArticlesMessage,
  showLoading,
  clearRedirect,
} = articlesSlice.actions;

export default articlesSlice.reducer;
