import axios from "axios";

import {
  // collection,
  // getDocs,
  // query,
  // orderBy,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

import { db } from "auth/FirebaseAuth";
import { AUTH_USER_DATA } from "constants/AuthConstant";
import { API_BASE_URL } from "constants/ApiConstant";

const FirebaseUserService = {};

FirebaseUserService.getUserData = async () => {
  try {
    if (!JSON.parse(localStorage.getItem(AUTH_USER_DATA))) return;
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    const userDataDocRef = doc(db, `users/${owner_uid}`);

    const userDataSnapshot = await getDoc(userDataDocRef);

    if (userDataSnapshot.exists()) {
      const userData = userDataSnapshot.data();
      return userData;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

FirebaseUserService.saveUserFCMToken = async token => {
  try {
    // Получаем данные авторизованного пользователя из localStorage
    const userData = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    if (!userData) return false;

    const { owner_uid } = userData;
    const userDataDocRef = doc(db, `users/${owner_uid}`);

    // Получаем данные пользователя из Firestore
    const userDataSnapshot = await getDoc(userDataDocRef);

    // Если документ пользователя существует
    if (userDataSnapshot.exists()) {
      const userData = userDataSnapshot.data();

      // Проверяем наличие массива FCMTokens
      const { FCMTokens = [] } = userData;

      // Проверяем, существует ли уже данный токен в массиве
      if (!FCMTokens.includes(token)) {
        // Если токен отсутствует, добавляем его в массив
        const updatedTokens = [...FCMTokens, token];

        // Обновляем данные пользователя в Firestore
        await updateDoc(userDataDocRef, { FCMTokens: updatedTokens });

        // Возвращаем true, так как токен был добавлен
        return true;
      }

      // Если токен уже существует в массиве, возвращаем true
      return true;
    } else {
      // Если документ пользователя не найден, возвращаем null
      return false;
    }
  } catch (error) {
    throw error; // Обрабатываем и выбрасываем ошибку
  }
};

FirebaseUserService.updateUserData = async updatedData => {
  try {
    const storedUser = JSON.parse(localStorage.getItem(AUTH_USER_DATA));

    if (!storedUser) {
      throw new Error("User data not found in localStorage.");
    }

    const { owner_uid } = storedUser;
    const userDataDocRef = doc(db, `users/${owner_uid}`);

    const userDataSnapshot = await getDoc(userDataDocRef);

    if (!userDataSnapshot.exists()) {
      throw new Error("User data not found in Firestore.");
    }

    const currentData = userDataSnapshot.data();

    const updatedUserData = {
      ...currentData,
      displayName:
        updatedData.displayName !== undefined
          ? updatedData.displayName
          : currentData.displayName,
      autoApproveSocialMedia:
        updatedData.autoApproveSocialMedia !== undefined
          ? updatedData.autoApproveSocialMedia
          : currentData.autoApproveSocialMedia,
    };

    await updateDoc(userDataDocRef, updatedUserData);

    localStorage.setItem(AUTH_USER_DATA, JSON.stringify(updatedUserData));

    return updatedUserData;
  } catch (error) {
    throw error;
  }
};

FirebaseUserService.getArticlesCounts = async projectId => {
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    const url = `${API_BASE_URL}/articles/counts/${owner_uid}/${projectId}`;
    const result = await axios.get(url);

    return result.data;
  } catch (error) {
    throw error;
  }
};

FirebaseUserService.postArticle = async data => {
  try {
    const url = `${API_BASE_URL}/articles/create`;
    // const response = await axios.post(url, data);
    const response = await axios.post(url, data);
    return response.data;
    // return;
  } catch (error) {
    throw error;
  }
};

export default FirebaseUserService;
